<template>
  <!-- <v-container id="dashboard" fluid tag="section"> -->
  <v-row>
    <v-col cols="12">
      <base-material-card color="success" inline title="Scientometrics" class="px-5 py-3">
        <v-col cols="12">
          <v-row :dense="dense" class="text-no-wrap" align="center">
            <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
              {{ $t("Year") }}
            </v-col>
            <v-col>
              <v-text-field v-model="year" :rules="inputValidate" clearable> </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row :dense="dense" class="text-no-wrap" align="center">
            <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
              {{ $t("Type") }}
            </v-col>
            <v-col>
              <v-select v-model="type" :rules="inputValidate" :items="['if', 'ais']" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row :dense="dense" class="text-no-wrap" align="center">
            <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
              {{ $t("File") }}
            </v-col>
            <v-col cols="9">
              <v-text-field
                v-model="record.file_name"
                :rules="inputValidate"
                clearable
                readonly
                @click="$refs.file.click()"
                @click:clear="
                  record.file_name = ''
                  record.file = null
                "
              >
                <template v-slot:prepend>
                  <v-icon @click="$refs.file.click()">
                    mdi-paperclip
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <div v-show="false">
            <input ref="file" type="file" accept=".json" @change="fileSelected" />
          </div>
        </v-col>
        <v-col cols="12">
          Example file:
          <pre>
  [
    {
      "cat": "ACOUSTICS",
      "index": "SCIENCE",
      "name": "ULTRASOUND IN OBSTETRICS & GYNECOLOGY",
      "issn": "0960-7692",
      "zona": 1,
      "top": 1
    },
    ...
  ]
          </pre>
        </v-col>
        <v-col>
          <v-progress-linear :value="(index / records.length) * 100" />
        </v-col>
        <v-col cols="12" class="d-flex">
          <v-spacer />
          <v-btn color="primary" :disabled="importing || !fileLoaded" @click="startImport"> {{ $t("Import") }} </v-btn>
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <v-subheader>{{ $t("Import IF values") }}</v-subheader>
        </v-col>
        <v-col cols="12">
          <v-row :dense="dense" class="text-no-wrap" align="center">
            <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
              {{ $t("Year") }}
            </v-col>
            <v-col>
              <v-text-field v-model="yearIF" :rules="inputValidate" clearable> </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row :dense="dense" class="text-no-wrap" align="center">
            <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
              {{ $t("IF File") }}
            </v-col>
            <v-col cols="9">
              <v-text-field
                v-model="recordIF.file_name"
                :rules="inputValidate"
                clearable
                readonly
                @click="$refs.fileif.click()"
                @click:clear="
                  recordIF.file_name = ''
                  recordIF.file = null
                "
              >
                <template #prepend>
                  <v-icon @click="$refs.fileif.click()">
                    mdi-paperclip
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <div v-show="false">
            <input ref="fileif" type="file" accept=".csv" @change="fileIFSelected" />
          </div>
        </v-col>
        <v-col cols="12">
          <div>
            {{ $t("CSV file example:") }}
          </div>
          <div>
            Rank,Full Journal Title,JCR Abbreviated Title,ISSN,Journal Impact Factor
          </div>
          <div>
            1,CA-A CANCER JOURNAL FOR CLINICIANS,CA-CANCER J CLIN,0007-9235,292.278
          </div>
          <div>
            2,NEW ENGLAND JOURNAL OF MEDICINE,NEW ENGL J MED,0028-4793,74.699
          </div>
          <div>
            3,Nature Reviews Materials,NAT REV MATER,2058-8437,71.189
          </div>
        </v-col>
        <v-col>
          <v-progress-linear :value="(indexIF / recordsIF.length) * 100" />
        </v-col>
        <v-col cols="12" class="d-flex">
          <v-spacer />
          <v-btn color="primary" :disabled="importingIF || !fileLoadedIF" @click="startImportIF">
            {{ $t("Import IF") }}
          </v-btn>
        </v-col>
      </base-material-card>
    </v-col>
  </v-row>
  <!-- </v-container> -->
</template>
<script>
import axios from "../../../../plugins/axios"
export default {
  name: "Home",
  data() {
    return {
      year: 2020,
      yearIF: 2019,
      type: "if",
      importing: false,
      importingIF: false,
      fileLoaded: false,
      fileLoadedIF: false,
      dense: true,
      hided: false,
      records: [],
      recordsIF: [],
      indexIF: -1,
      subindexIF: 0,
      index: -1,
      subindex: 0,
      recordIF: {},
      record: {}
    }
  },
  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  methods: {
    fileSelected(e) {
      this.$log("fileSelected ", e)
      this.record.file = e.target.files[0]
      this.$set(this.record, "file_name", e.target.files[0].name)
      this.record.name = e.target.files[0].name
      this.record.content_type = e.target.files[0].type
      this.$log("json ", e.target.files[0])
      const reader = new FileReader()
      reader.onload = this.onReaderLoad
      reader.readAsText(e.target.files[0])
    },
    fileIFSelected(e) {
      this.$log("fileSelected ", e)
      this.recordIF.file = e.target.files[0]
      this.$set(this.recordIF, "file_name", e.target.files[0].name)
      const config = {
        header: true,
        skipEmptyLines: true,
        complete: this.parseComplete
      }
      this.$papa.parse(this.recordIF.file, config)
    },
    parseComplete(e) {
      this.$log("parseC ", e)
      this.recordsIF = e.data
      this.fileLoadedIF = true
    },
    startImportIF() {
      this.importingIF = true
      this.indexIF = -1
      this.subindexIF = 0
      this.importDataIF()
    },
    importDataIF() {
      this.indexIF++
      this.subindexIF++
      if (this.indexIF < this.recordsIF.length || this.indexIF < 10) {
        const record = this.recordsIF[this.indexIF]
        //this.$log("recordsIF ", this.recordsIF)
        //this.$log("indexIF ", this.indexIF)
        if (record.ISSN != "****-****") {
          axios
            .get('infuni_scientometrics?where={"issn":"' + record.ISSN + '"}')
            .then(res => {
              //this.$log("get res ", res)
              if (res.status == 200) {
                if (res.data._items.length > 0) {
                  //this.$log("issn ", record.issn)
                  const grec = res.data._items[0]
                  const gid = grec._id
                  const gtag = grec._etag
                  grec._id = undefined
                  grec._etag = undefined
                  grec._latest_version = undefined
                  grec._links = undefined
                  grec._updated = undefined
                  grec._version = undefined
                  grec._created = undefined
                  if (grec.if_ais_val) {
                    if (grec.if_ais_val.find(itm => itm.year == this.yearIF)) {
                      grec.if_ais_val.find(itm => itm.year == this.yearIF).if = record["Journal Impact Factor"]
                    } else {
                      grec.if_ais_val.push({ year: this.yearIF, if: record["Journal Impact Factor"] })
                    }
                  } else {
                    grec.if_ais_val = [{ year: this.yearIF, if: record["Journal Impact Factor"] }]
                  }
                  //this.$log("put ", grec)
                  axios
                    .put("infuni_scientometrics/" + gid, grec, {
                      headers: {
                        "If-Match": gtag
                      }
                    })
                    .finally(() => {
                      this.importDataIF()
                    })
                } else {
                  this.importDataIF()
                }
              } else {
                this.importDataIF()
              }
            })
            .catch(() => {
              this.importDataIF()
            })
        } else {
          this.importDataIF()
        }
      } else {
        this.importingIF = false
      }
    },
    onReaderLoad(event) {
      this.records = JSON.parse(event.target.result)
      //   console.log(obj)
      this.fileLoaded = true
    },
    startImport() {
      axios.get("app_dictionary/60d9c4b1140adb74fd25fdb2").then(res => {
        const arr = res.data.content
        this.records.map(rec => {
          if (!arr.find(itm => itm.value == rec.cat)) {
            arr.push({ name: rec.cat, value: rec.cat })
          }
        })
        axios.put(
          "app_dictionary/60d9c4b1140adb74fd25fdb2",
          {
            active: true,
            name: "Scientometrics categories",
            value: "scientometricsCategories",
            content: arr
          },
          {
            headers: {
              "If-Match": res.data._etag
            }
          }
        )
      })
      this.importing = true
      this.index = -1
      this.subindex = 0
      this.importData()
    },
    importData() {
      this.index++
      this.subindex++
      if (this.index < this.records.length) {
        if (this.index > 0) {
          if (this.records[this.index].zona != this.records[this.index - 1].zona) {
            this.subindex = 1
          }
        }
        const record = this.records[this.index]
        const srec = { year: this.year }
        srec[this.type] = { quarter: record.zona, order_no: record.top, order_quarter: this.subindex }
        const rec = {
          issn: record.issn,
          journal: record.name,
          index: [record.index],
          scie: [{ category: record.cat, indices: [srec] }]
        }
        //this.$log("rec ", rec)
        axios
          .get('infuni_scientometrics?where={"issn":"' + record.issn + '"}')
          .then(res => {
            //this.$log("get res ", res)
            if (res.status == 200) {
              if (res.data._items.length == 0) {
                axios.post("infuni_scientometrics", rec).finally(() => {
                  this.importData()
                })
              } else {
                //this.$log("issn ", record.issn)
                const grec = res.data._items[0]
                const gid = grec._id
                const gtag = grec._etag
                grec._id = undefined
                grec._etag = undefined
                grec._latest_version = undefined
                grec._links = undefined
                grec._updated = undefined
                grec._version = undefined
                grec._created = undefined
                if (grec.scie.find(itm => itm.category == record.cat)) {
                  const cat = grec.scie.find(itm => itm.category == record.cat)
                  if (cat.indices.find(it => it.year == this.year)) {
                    cat.indices.find(it => it.year == this.year)[this.type] = {
                      quarter: record.zona,
                      order_no: record.top,
                      order_quarter: this.subindex
                    }
                  } else {
                    grec.scie[grec.scie.findIndex(itm => itm.category == record.cat)].indices.push(srec)
                  }
                } else {
                  grec.scie.push({ category: record.cat, indices: [srec] })
                }
                if (grec.index.indexOf(record.index) < 0) {
                  grec.index.push(record.index)
                }
                //this.$log("put ", grec)
                axios
                  .put("infuni_scientometrics/" + gid, grec, {
                    headers: {
                      "If-Match": gtag
                    }
                  })
                  .finally(() => {
                    this.importData()
                  })
              }
            } else {
              this.importData()
            }
          })
          .catch(() => {
            this.importData()
          })
      } else {
        this.importing = false
      }
    },
    importDataOld() {
      this.index++
      if (this.index < this.records.length) {
        const record = this.records[this.index]
        const rec = { issn: record.issn, name: record.name, index: record.index, scie: {} }
        rec.scie[record.cat] = { 2020: { ais: { zona: record.zona, tip: record.top } } }
        //this.$log("rec ", rec)
        axios
          .get('infuni_journals?where={"issn":"' + record.issn + '"}')
          .then(res => {
            //this.$log("get res ", res)
            if (res.status == 200) {
              if (res.data._items.length == 0) {
                axios.post("infuni_journals", rec).finally(() => {
                  this.importData()
                })
              } else {
                //this.$log("issn ", record.issn)
                const grec = res.data._items[0]
                const gid = grec._id
                const gtag = grec._etag
                grec._id = undefined
                grec._etag = undefined
                grec._latest_version = undefined
                grec._links = undefined
                grec._updated = undefined
                grec._version = undefined
                grec._created = undefined
                if (grec.scie[record.cat]) {
                  if (grec.scie[record.cat]["2020"]) {
                    grec.scie[record.cat]["2020"].ais = { zona: record.zona, tip: record.top }
                  } else {
                    grec.scie[record.cat]["2020"] = { ais: { zona: record.zona, tip: record.top } }
                  }
                } else {
                  grec.scie[record.cat] = { 2020: { ais: { zona: record.zona, tip: record.top } } }
                }
                if (grec.index.indexOf(record.index) < 0) {
                  grec.index.push(record.index)
                }
                axios
                  .patch("infuni_journals/" + gid, grec, {
                    headers: {
                      "If-Match": gtag
                    }
                  })
                  .finally(() => {
                    this.importData()
                  })
              }
            } else {
              this.importData()
            }
          })
          .catch(() => {
            this.importData()
          })
      }
    }
  }
}
</script>
<style></style>
